import Framework from '@Framework/index';
import LoadingService from '@/services/LoadingService';
import Vue from 'vue';
import installCTSLibrary from '@/plugins/CentralToolServiceLibrary';
import ioc from '@/services/ioc';
import registerNotificationSystem from '@/services/notificationSystem';
// @ts-ignore
import {installI18n} from '@/plugins/i18n';
import registerRouter from './router';
import App from './App.vue';
import store from './store';

Vue.config.productionTip = false;
Vue.use(Framework);
Vue.prototype.$loading = new LoadingService();

const languageSwitcher = installI18n(Vue);
installCTSLibrary(Vue, store);
const router = registerRouter(store);
const rentalList: string[] = [
    'FL-R-PAG18-XS',
    'FL-R-PAG18-S',
    'FL-R-PAG18-PV-XS',
    'FL-R-PAG18-PV-S',
    'FL-R-PAG18-SV-XS',
    'FL-R-PAG18-SV-S',
    'FL-R-BAT-182001',
    'FL-R-BAT-185001',
    'FL-R-BAT-072501',
    'FL-R-CHR-181501',
    'FL-R-CHR-186501',
    'FL-R-CHR072001',
    'FL-R-PCS18-XS',
    'FL-R-PCS18-S',
    'FL-R-PCH-421350',
    'FL-R-PDD18-P100-XS',
    'FL-R-PDD18-P100-S',
    'FL-R-PDD18-55-XS',
    'FL-R-PDD18-55-S',
    'FL-R-PDD18-70-XS',
    'FL-R-PDD18-70-S',
    'FL-R-PDS-AF1',
    'FL-R-PDS18-XS',
    'FL-R-PDS18-S',
    'FL-R-WW90II-S',
    'FL-R-WW90II-XS',
    'FL-R-PID18-XS',
    'FL-R-PID18-S',
    'FL-R-PIW18-XS',
    'FL-R-PIW18-S',
    'FL-R-PRH-26850',
    'FL-R-PRH18-XS',
    'FL-R-PRH18-S',
    'FL-R-SC40II-S',
];
ioc.set(
    'rentalList',
    function getRentalList() {
        return rentalList;
    },
    {},
);

let config = Framework.getVueConfig(App);
config = {
    ...config,
    // i18n,
    languageSwitcher,
    router,
    store,
};
const app = new Vue(config);
// todo - występował problem że rejestrowało mi wielokrotnie tne serwis w związku czym duplikowały się wiadomości
// i wyświetlały 1, 2, 4, 8 krotnie
const notificationSystem = registerNotificationSystem(app, store);
ioc.set(
    'service.notificationSystem',
    function getNotificationSystem() {
        return notificationSystem;
    },
    {},
);

app.$mount('#app');