import {RouteConfig} from 'vue-router';

function routesDevicesIndex(): RouteConfig[] {
    return [
        {
            path: '/user/devices',
            name: 'Devices',
            component: () => import(/* webpackChunkName: "devices" */ '@/views/user/devices/DevicesIndex.vue'),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: '', icon: '', title: 'cts.routes.routes-your-products'},
                ],
                routeFor: ['CLIENT'],
            },
        },
    ];
}

function routesNewDevice(): RouteConfig[] {
    return [
        {
            path: '/user/devices/new',
            name: 'NewDevice',
            component: () => import(/* webpackChunkName: "new-device" */ '@/views/user/devices/NewDevice.vue'),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: 'Devices', icon: '', title: 'cts.routes.routes-your-products'},
                    {name: '', icon: '', title: 'cts.routes.routes-add-new-product'},
                ],
                routeFor: ['CLIENT'],
            },
        },
    ];
}

function routesDeviceManuals(): RouteConfig[] {
    return [
        {
            path: '/user/devices/manuals',
            name: 'DeviceManuals',
            component: () => import(/* webpackChunkName: "device-manuals" */ '@/views/user/devices/DeviceManuals.vue'),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: 'Devices', icon: '', title: 'cts.routes.routes-your-products'},
                    {name: '', icon: '', title: 'cts.btn-download-manuals'},
                ],
                routeFor: ['CLIENT'],
            },
        },
    ];
}

function routesSingleDevice(): RouteConfig[] {
    return [
        {
            path: '/user/devices/:id',
            name: 'UserSingleDevice',
            component: () => import(
                '@/views/user/devices/SingleDevice.vue'
                /* webpackChunkName: "user-single-device" */
            ),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: 'Devices', icon: '', title: 'cts.routes.routes-your-products'},
                    {name: '', icon: '', title: 'cts.routes.routes-product'},
                ],
                routeFor: ['CLIENT'],
            },
        },
    ];
}

export function routesDevices(): RouteConfig[] {
    return [
        ...routesDevicesIndex(),
        ...routesNewDevice(),
        ...routesDeviceManuals(),
        ...routesSingleDevice(),
    ];
}

export default routesDevices;