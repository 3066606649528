import ioc from '@/services/ioc';

export default () => {
    const sitesManager = ioc.get('sitesManager') as any;

    return {
        defaultLanguage: sitesManager.getLanguage('pl'),
        languages: ['pl', 'en', 'cs'],
        translationsDirectory: './translations',
        projectsMap: {
            514679: 'cts',
            319995: '',
            293481: '',
        },
    };
};