import SsoApi from '@/services/api/SsoApi';
import ClientMapper from '@/services/domain/client/ClientMapper';
import RequestArchiver from '@/services/domain/request/RequestArchiver';
import {ResponseAlertsMap} from '@/services/domain/ResponseAlertsMap';
import RequestRegister from '@/services/domain/request/RequestRegister';
import ResponseDataExtractor from '@/services/domain/ResponseDataExtractor';
import ResponseHandler from '@/services/domain/ResponseHandler';
import SearchService from '@/services/domain/SearchService';
import * as SitesManager from '@/../core/sites-manager/SitesManager';
import AddressesService from '@/services/domain/addresses/AddressesService';
import AdminApi from '@/services/api/AdminApi';
import AdminDevicesProvider from '@/services/domain/device/AdminDevicesProvider';
import AssetsApi from '@/services/api/AssetsApi';
import ClientApi from '@/services/api/ClientApi';
import ClientsProvider from '@/services/domain/client/ClientsProvider';
import DeviceArchiverService from '@/services/domain/device/DeviceArchiverService';
import DeviceRegistrant from '@/services/domain/device/DeviceRegistrator';
import DeviceService from '@/services/domain/device/DeviceService';
import DevicesProvider from '@/services/domain/device/DevicesProvider';
import StatusBadges from '@/services/domain/statuses-badge/StatusBadges';
import {
    deviceStatusDictionary,
    deviceStatusForAdminDictionary,
} from '@/services/domain/statuses/dictionaries/deviceStatusDictionaries';
import {
    requestStatusDictionary,
    requestStatusForAdminDictionary,
} from '@/services/domain/statuses/dictionaries/requestStatusDictioneries';
import StatusCalculators from '@/services/domain/statuses/StatusCalculators';
import Filterer from '@/services/filterer/Filterer';
import ImagesProvider from '@/services/domain/images/ImagesProvider';
import MessageBuilder from '@/services/message-factory/MessageBuilder';
import MessageDirector from '@/services/message-factory/MessageDirector';
import MessagesService from '@/services/domain/messages/messagesService';
import NotificationService from '@/services/domain/NotificationService';
import RequestService from '@/services/domain/request/RequestService';
import RequestsApi from '@/services/api/RequestsApi';
import RequestsProvider from '@/services/domain/request/RequestsProvider';
import deviceMapper from '@/services/domain/device/deviceMapper';
import ioc from '@/services/ioc';
import sparePartsService from '@/services/sparePartsService';
import AuthService from '@/services/AuthService';
import RequestManager from '@/services/requestManager';
import SsoService from '@/services/SsoService';
import appConfig from '@AppConfig/AppConfig';
import {configs} from '@Framework/installConfigs';
// @ts-ignore
import ResponseStatusCodeManager from '@rawlplug/response-manager';
import getVueEnv from '@/env';
import ConsentsProvider from '@/services/domain/consents/ConsentsProvider';
import consentsUrls from '@/resources/consents-urls';
import ConsentsURLReplacer from '@/services/domain/consents/ConsentsURLReplacer';

const apiConfig = appConfig().api;

const arr = [
    [
        'sitesManager',
        function sitesManager() {
            return SitesManager;
        },
        {},
    ],
    ['service.ssoService',
        SsoService,
        {
            args: [
                '@service.requestManager',
                '@api.sso',
                '@service.responseHandler',
                '@service.responseDataExtractor',
            ],
        },
    ],
    ['service.authService',
        AuthService,
        {
            args: [
                '@service.requestManager',
                '@api.client',
                '@service.responseHandler',
                '@service.responseDataExtractor',
            ],
        },
    ],
    ['service.requestManager', RequestManager, {args: [window.fetch.bind(window)]}],
    [
        'service.filterer',
        function filterer() {
            return new Filterer();
        },
        {},
    ],
    [
        'service.messagesService',
        MessagesService,
        {
            args: ['@service.requestManager', '@api.client'],
        },
    ],
    [
        'service.deviceMapper',
        function createDeviceMapper() {
            return deviceMapper;
        },
        {},
    ],
    [
        'service.sparePartsService',
        sparePartsService,
        {
            args: ['@service.requestManager', '@api.client'],
        },
    ],
    ['api.sso', SsoApi, {args: [getVueEnv('SSO_RID_PATH')]}],
    ['api.assets', AssetsApi, {args: ['https://assets.rawlplug.com']}],
    ['api.client', ClientApi, {args: [apiConfig.apiUri]}],
    ['api.requests', RequestsApi, {args: [apiConfig.apiUri]}],
    ['api.admin', AdminApi, {args: [apiConfig.apiUri]}],
    [
        'provider.adminRequestsProvider',
        RequestsProvider,
        {
            args: [
                '@service.requestManager',
                '@api.requests',
                ioc,
                '@service.responseHandler',
                '@service.responseDataExtractor',
            ],
        },
    ],
    ['service.addresses',
        AddressesService,
        {
            args: [
                '@service.requestManager',
                '@api.client',
                '@service.authService',
                '@service.responseDataExtractor',
            ],
        },
    ],
    [
        'provider.devicesProvider',
        DevicesProvider,
        {
            args: ['@service.requestManager', '@api.client', ioc, '@service.deviceMapper'],
        },
    ],
    [
        'provider.adminDevicesProvider',
        AdminDevicesProvider,
        {
            args: ['@service.requestManager', '@api.admin', ioc, '@service.deviceMapper'],
        },
    ],
    [
        'provider.clientsProvider',
        ClientsProvider,
        {
            args: ['@service.requestManager', '@api.client'],
        },
    ],
    ['provider.images', ImagesProvider, {args: ['@api.assets']}],
    [
        'service.deviceRegistrant',
        DeviceRegistrant,
        {args: ['@service.requestManager', '@api.client', '@service.deviceMapper']},
    ],
    [
        'service.requestRegister',
        RequestRegister,
        {args: ['@service.requestManager', '@api.client', '@service.authService']},
    ],
    ['builder.message', MessageBuilder, {args: []}],
    ['service.messageDirector', MessageDirector, {args: ['@builder.message']}],
    ['service.notification', NotificationService, {args: ['@service.notificationSystem', '@service.messageDirector']}],
    ['service.request', RequestService, {args: ['@service.requestManager', '@api.admin']}],
    // @ts-ignore todo
    ['translate', function translate(translator) {
        return translator.t.bind(translator);
    }, {args: ['@translator']}],
    [
        'service.statusCalculators',
        StatusCalculators,
        {args: [deviceStatusDictionary, requestStatusDictionary]},
    ],
    [
        'service.statusCalculatorsForAdmin',
        StatusCalculators,
        {args: [deviceStatusForAdminDictionary, requestStatusForAdminDictionary]},
    ],
    [
        'service.statusBadge',
        StatusBadges,
        {args: []},
    ],
    ['service.device', DeviceService, {args: ['@service.requestManager', '@api.admin', '@service.messagesService']}],
    ['service.deviceArchiver',
        DeviceArchiverService,
        {
            args: ['@service.requestManager', '@service.deviceMapper', '@api.client'],
        },
    ],
    ['service.responseStatusCodeManager', ResponseStatusCodeManager, {args: []}],
    ['service.alertsMap', ResponseAlertsMap, {args: []}],
    ['service.responseHandler',
        ResponseHandler,
        {
            args: ['@service.responseStatusCodeManager', '@service.alertsMap', '@service.notification', '@translator'],
        },
    ],
    ['service.responseDataExtractor', ResponseDataExtractor, {args: []}],
    [
        'service.search',
        SearchService,
        {
            args: [
                '@service.requestManager',
                '@api.admin',
                '@service.responseDataExtractor',
                '@service.clientMapper',
            ],
        },
    ],
    [
        'service.clientMapper',
        ClientMapper,
        {
            args: [],
        },
    ],
    [
        'service.requestArchiver',
        RequestArchiver,
        {
            args: [
                '@service.requestManager',
                '@api.client',
            ],
        },
    ],
    [
        'service.consentsProvider',
        ConsentsProvider,
        {
            args: [
                '@translator',
                consentsUrls,
                new ConsentsURLReplacer(),
            ],
        },
    ],
] as const;

export type ServicesName = typeof arr[number][0]
    | typeof configs[number][0]
    | 'translator'
    | 'config.footer'
    | 'config.components'
    | 'config.forms'
    | 'config.router'
    | 'config.languageSwitcher'
    | 'store';

export default () => arr;