import FooterRenderer from '@Framework/components/FooterRenderer.vue';
import FlexContainer from '@Framework/components/containers/FlexContainer';
import CopyrightText from '@Framework/components/CopyrightText.vue';
import BaseLink from '@/components/BaseLink.vue';
import consentsUrls from '@/resources/consents-urls';
import ioc from '@/services/ioc';
import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';

export default () => {
    const translator = ioc.get('translator') as LanguageSwitcherInterface;
    const t = translator.t.bind(translator);

    return ({
        rendererComponent: FooterRenderer,
        displayFooter: true,
        items: [
            [
                FlexContainer,
                {
                    justifyEnd: true,
                    wrap: true,
                    alignCenter: true,
                    gap: '5px',
                },
                [
                    [
                        BaseLink,
                        {
                            title: t('cts.footer.footer-privacy-policy'),
                            href: consentsUrls['privacy-policy'],
                            target: '_blank',
                        },
                        [],
                    ],
                    [
                        BaseLink,
                        {
                            title: t('cts.footer.footer-regulations'),
                            href: consentsUrls['terms-and-conditions-link'],
                            target: '_blank',
                        },
                        [],
                    ],
                    [
                        BaseLink,
                        {
                            title: t('cts.footer.footer-regulations-devices'),
                            href: consentsUrls['replacement-device-terms-link'],
                            target: '_blank',
                        },
                        [],
                    ],
                    [
                        CopyrightText,
                        {
                            companyName: 'Rawlplug S.A.',
                        },
                        [],
                    ],
                ],
            ],
        ],
    });
};